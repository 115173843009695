angular.module("UI8.components").component("accountPurchases", {
  template: `<div class="page page--additional" ng-if="$ctrl.hasPurchases">
      <div class="page__container container">
        <div class="page__hero">
          <h1 class="page__title">Your purchases</h1>
          <div class="page__description">Manage your subscriptions, downloads and invoices.</div>
        </div>
        <div class="purchases">
          <div class="purchases__list">
            <pass-tile
              ng-repeat="subscription in $ctrl.data.subscriptions"
              subscription="subscription"
              index="$index"
              settings="$ctrl.settings"
              on-card-remove="$ctrl.onCardRemove()"
              is-paypal-subscription="$ctrl.lastSubscription.paypal_subscription_id"
              active-paypal-subscription="$ctrl.activePaypalSubscription"
              on-cancel-paypal-subscription="$ctrl.cancelPaypalSubscription();"
            ></pass-tile>
            <order-item ng-repeat="item in $ctrl.nonBundleItems" item="item" user="$ctrl.data.user"></order-item>
            <order-item ng-repeat="item in $ctrl.bundleItems" item="item" user="$ctrl.data.user"></order-item>
          </div>
        </div>
      </div>
    </div>
    <div class="spage" ng-if="!$ctrl.hasPurchases">
      <div class="spage__background">
        <img src="/img/no-purchases.jpg" alt="Oops!">
      </div>
      <div class="spage__container">
        <div class="spage__title">No purchases yet.</div>
        <div class="spage__content">Your products and subscriptions will appear on this page once you make any purchases.</div>
        <a href="/category/featured-products" class="btn btn--submit">Explore products</a>
      </div>
      <div class="spage__copyright">© 2025, Robot Global FZCO / UI8</div>
    </div>`,

  controller: function (
    $http,
    ProductService,
    PushService,
    CartService,
    AccountService,
    PaymentService,
    SessionService,
    FlashService
  ) {
    var ctrl = this;

    ctrl.$onInit = function () {
      ctrl.loading = false;
      ctrl.tab = "products";
      ctrl.page = 1;
      ctrl.hasPurchases =
        ctrl.data.subscriptions || ctrl.data.items || ctrl.data.jobs;
      ctrl.hasNonJobs = ctrl.data.subscriptions || ctrl.data.items;
      ctrl.hasJobs = ctrl.data.jobs;
      ctrl.nonBundleItems = ctrl.data.items
        ? ctrl.data.items.filter(function (i) {
            return i.product.type !== "bundle";
          })
        : null;
      ctrl.bundleItems = ctrl.data.items
        ? ctrl.data.items.filter(function (i) {
            return i.product.type === "bundle";
          })
        : null;

      ctrl.addPassToCart = function () {
        CartService.addPassToCart("55314fb422912718375a0196", 1);
      };

      AccountService.settings().then((response) => {
        ctrl.settings = response;
      });

      ctrl.lastSubscription = ctrl.data.subscriptions[0];
      if (ctrl.lastSubscription.paypal_subscription_id) {
        ctrl.activePaypalSubscription = null;

        getActivePaypalSubscription();
      }

      ctrl.onCardRemove = function () {
        PaymentService.stripeCardRemove(
          ctrl.settings.user.stripe_customer,
          ctrl.settings.defaultCard.id
        )
          .then(() => {
            ctrl.settings.defaultCard = null;
            SessionService.setCurrentUser();
            FlashService.set(
              "message",
              "Auto-renewal for the All-Access Pass has been canceled and you will no longer be billed"
            );
          })
          .catch(() => {
            FlashService.set(
              "error",
              "Unable to cancel auto-renewal. Please contact support"
            );
          });
      };

      ctrl.cancelPaypalSubscription = function () {
        if (!ctrl.activePaypalSubscription) return;

        $http
          .post("/api/paypal/cancel-subscription", {
            subscriptionId: ctrl.activePaypalSubscription.id,
          })
          .then(
            function (response) {
              // console.log(response);
              if (response.data.status === "error")
                return FlashService.set("error", response.data.message);
              FlashService.set("message", response.data.message);

              getActivePaypalSubscription();
            },
            function (error) {
              console.log(error);
              return FlashService.set(
                "error",
                error.data.error ||
                  error.error ||
                  "Unable to get active subscription. Please try again later."
              );
            }
          );
      };

      initPushNotificationHandler();
    };

    ctrl.getCardBackground = function (item) {
      return { "background-image": "url(" + item.product.card_image + ")" };
    };

    ctrl.next = function () {
      ctrl.paginating = true;
      return getPage(ctrl.page + 1);
    };

    function getPage(page) {
      if (ctrl.loading) {
        return;
      }

      ctrl.loading = true;
      ctrl.page = page;

      if (page === 1) {
        ctrl.data.products = [];
      }

      var options = {
        sort: "recent",
        page: ctrl.page,
      };

      ProductService.list(options)
        .then(function (response) {
          ctrl.data.products = ctrl.data.products.concat(response.products);
        })
        .finally(function () {
          ctrl.loading = false;
          ctrl.paginating = false;
        });
    }

    function getActivePaypalSubscription() {
      $http
        .get("/api/paypal/get-subscription", {
          params: {
            subscriptionId: ctrl.lastSubscription.paypal_subscription_id,
          },
        })
        .then(
          function (response) {
            console.log(response);
            ctrl.activePaypalSubscription =
              response.data.status === "ACTIVE" ? response.data : null;
          },
          function (error) {
            console.log(error);
          }
        );
    }

    function initPushNotificationHandler() {
      if (!("serviceWorker" in navigator)) return;

      if (!("PushManager" in window)) return;

      PushService.setUpPush()
        .then(function (subscription) {
          ctrl.pushSubscription = subscription;

          return new Promise(function (resolve) {
            resolve(subscription);
          });
        })
        .then(function (subscription) {
          if (!subscription) {
            return PushService.subscribe();
          } else {
            return new Promise(function (resolve) {
              resolve(subscription);
            });
          }
        })
        .then(function (subscription) {
          ctrl.pushSubscription = subscription;
        })
        .catch(function () {
          ctrl.pushSubscription = null;
        });
    }
  },

  bindings: {
    data: "<",
  },
});
